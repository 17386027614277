import './volunteer-search-form.scss'

import { Button, TypeDisplay } from 'raspberry-pi-bits'
import React, { useContext } from 'react'

import ParamsHelper from '@/helpers/ParamsHelper'
import VolunteerContext from '@/contexts/volunteer'
import ccImage from '../images/codeclub-logo.svg'
import cdImage from '../images/coderdojo-logo.svg'

export const submitHandler = (event, setLocation, setPage) => {
  event.preventDefault()

  const loc = event.target.elements.location.value

  ParamsHelper.set('location', loc)
  ParamsHelper.set('page', 1)

  setLocation(loc)
  setPage(1)
}

const VolunteerSearchForm = () => {
  const { setLocation, setPage } = useContext(VolunteerContext)

  return (
    <div className="c-volunteer-search-form">
      <div className="o-container">
        <div className="c-volunteer-search-form__container">
          <div className="c-volunteer-search-form__child-wide">
            <TypeDisplay>Find a club near you:</TypeDisplay>
          </div>
          <div className="c-volunteer-search-form__child-logos">
            <img
              src={cdImage}
              className="c-volunteer-search-form__logo"
              alt="CoderDojo Logo"
            />
            <img
              src={ccImage}
              className="c-volunteer-search-form__logo"
              alt="Code Club Logo"
            />
          </div>
        </div>

        <form
          onSubmit={(event) => submitHandler(event, setLocation, setPage)}
          className="c-volunteer-search-form__form"
        >
          <div className="c-volunteer-search-form__input-container">
            <input
              className="c-input c-volunteer-search-form__input"
              type="search"
              name="location"
              placeholder="Postcode or town/city, country"
            />
          </div>
          <div className="c-volunteer-search-form__button-container">
            <Button>Search</Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default VolunteerSearchForm
