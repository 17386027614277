import React, { useCallback, useEffect, useState } from 'react'

import ParamsHelper from '@/helpers/ParamsHelper'
/* eslint-disable no-console */
import PropTypes from 'prop-types'
import { getOpportunities } from '@/helpers/volunteer'

const VolunteerContext = React.createContext({
  location: '',
  setLocation: () => {},
  opportunities: [],
  setOpportunities: () => {},
  totalResults: 0,
  setTotalResults: () => {},
  searchPerformed: false,
  setSearchPerformed: () => {},
  page: '1',
  setPage: () => {},
  loading: false,
  geocodedLocation: '',
})

const VolunteerContextProvider = ({ children }) => {
  const [totalPages, setTotalPages] = useState(0)
  const [location, setLocation] = useState(ParamsHelper.get('location', ''))
  const [page, setPage] = useState(ParamsHelper.get('page', '1'))
  const [opportunities, setOpportunities] = useState([])
  const [geocodedLocation, setGeocodedLocation] = useState('')
  const [totalResults, setTotalResults] = useState(0)
  const [searchPerformed, setSearchPerformed] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const loadOpportunities = useCallback(async () => {
    if (location !== '') {
      setLoading(true)
      setSearchPerformed(true)

      const jsonData = await getOpportunities(location, page)
      if (jsonData.error) {
        setError(true)
      } else {
        setTotalResults(jsonData.total)
        setOpportunities(jsonData.opportunities)
        setGeocodedLocation(jsonData.geocoded_location)
      }

      setLoading(false)
    }
  }, [location, page])

  const getTotalPages = useCallback(async () => {
    const perPage = 10
    setTotalPages(Math.ceil(totalResults / perPage))
  }, [totalResults])

  useEffect(() => {
    loadOpportunities()
    getTotalPages()
  }, [getTotalPages, loadOpportunities])

  const nextPage = (event, scrollTo = 0) => {
    event.preventDefault()
    const next = parseInt(page, 10) + 1
    ParamsHelper.set('page', next)
    setPage(next.toString())
    window.scrollTo(0, scrollTo)
  }

  const prevPage = (event, scrollTo = 0) => {
    event.preventDefault()
    const prev = parseInt(page, 10) - 1
    ParamsHelper.set('page', prev)
    setPage(prev.toString())
    window.scrollTo(0, scrollTo)
  }

  const volunteerContextValue = {
    location,
    setLocation,
    page,
    setPage,
    opportunities,
    totalResults,
    searchPerformed,
    nextPage,
    prevPage,
    totalPages,
    loading,
    error,
    geocodedLocation,
  }

  return (
    <VolunteerContext.Provider value={volunteerContextValue}>
      {children}
    </VolunteerContext.Provider>
  )
}

VolunteerContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default VolunteerContext
export { VolunteerContextProvider }
