import './volunteer-opportunity.scss'

import React from 'react'
import PropTypes from 'prop-types'

const VolunteerOpportunity = ({ opp, helper }) => {
  const {
    buttonText,
    formattedFrequency,
    logoAlt,
    logoImage,
    nextEvent,
  } = helper
  const { address, name, url } = opp

  const frequency = formattedFrequency(opp)
  const nextEventText = nextEvent(opp)

  return (
    <div className="c-volunteer-opportunity">
      <img
        className="c-volunteer-opportunity__logo"
        src={logoImage}
        alt={logoAlt}
      />
      <div className="c-volunteer-opportunity__details">
        <h2>{name}</h2>
        <p>{address}</p>
        <p className="c-volunteer-opportunity__frequency">{frequency}</p>
      </div>
      <div className="c-volunteer-opportunity__contact">
        {nextEventText}
        <a className="c-button c-button--secondary" href={url}>
          {buttonText}
        </a>
      </div>
    </div>
  )
}

VolunteerOpportunity.propTypes = {
  opp: PropTypes.object.isRequired,
  helper: PropTypes.object.isRequired,
}

export default VolunteerOpportunity
