import './volunteer-hero.scss'

import React from 'react'

const VolunteerHero = () => (
  <div className="c-volunteer-hero">
    <div className="o-container">
      <h1 className="c-volunteer-hero__title">
        <span className="c-volunteer-hero__title-emph">Volunteer</span>
        &nbsp; to help the next generation learn and create with code!
      </h1>
    </div>
  </div>
)

export default VolunteerHero
