import './translation-cta.scss'

import React from 'react'

const TranslationCTA = () => (
  <section className="c-translation-cta">
    <div className="o-container c-translation-cta__container">
      <a
        href="https://raspberrypi.org/translate"
        className="c-translation-cta__link"
      >
        Want to help us translate our content?
      </a>
    </div>
  </section>
)

export default TranslationCTA
