import clubLogoImage from '../components/volunteer/images/codeclub-logo.svg'

/* eslint-disable camelcase */
export const formattedFrequency = ({
  club_fields: { frequency, day, time_hours, time_mins },
}) => {
  const parts = [frequency, day, time_hours, time_mins]

  if (parts.every(x => x !== null && x !== '')) {
    return `${frequency} on a ${day} at ${time_hours}:${time_mins}`
  }
  return ''
}

export const nextEvent = () => {
  return null
}
export const logoAlt = 'Code Club Logo'
export const buttonText = 'Message Club Organiser'
export const logoImage = clubLogoImage

const CodeClubOpportunityHelper = {
  formattedFrequency,
  logoAlt,
  buttonText,
  logoImage,
  nextEvent,
}

export default CodeClubOpportunityHelper
