import './volunteer-info.scss'

import React from 'react'
import { Slice, TypeBase, TypeDisplay } from 'raspberry-pi-bits'

import heroImage from '../images/hero.png'
import ccImage from '../images/codeclub-logo.svg'
import cdImage from '../images/coderdojo-logo.svg'

const VolunteerInfo = () => {
  return (
    <Slice>
      <div className="o-container">
        <div className="c-volunteer-info">
          <img
            alt="A coding club"
            className="c-volunteer-info__hero"
            src={heroImage}
          />

          <div className="c-volunteer-info__title">
            <TypeDisplay className="u-weight-bold">Every week,</TypeDisplay>
            <TypeDisplay>
              {' '}
              our volunteer-powered coding clubs bring together{'  '}
            </TypeDisplay>
            <TypeDisplay className="u-weight-bold">300,000+ young</TypeDisplay>
            <TypeDisplay>&nbsp;people in over&nbsp;</TypeDisplay>
            <TypeDisplay className="u-weight-bold">160 countries</TypeDisplay>
          </div>
          <div className="c-volunteer-info__club-descriptor">
            <img
              src={cdImage}
              alt="CoderDojo Logo"
              className="c-volunteer-info__club-logo"
            />
            <div className="c-volunteer-info__club-description">
              <TypeBase element="p">
                CoderDojos are free, creative coding clubs in community spaces
                for young people aged 7&ndash;17
              </TypeBase>
              <a href="https://coderdojo.com/" className="c-link">
                Find out more
              </a>
            </div>
            <img
              src={ccImage}
              alt="Code Club Logo"
              className="c-volunteer-info__club-logo"
            />
            <div className="c-volunteer-info__club-description">
              <TypeBase element="p">
                Code Clubs are free, extracurricular, in-school coding clubs for
                young people aged 9&ndash;13
              </TypeBase>
              <a href="https://codeclub.org/" className="c-link">
                Find out more
              </a>
            </div>
          </div>
        </div>
      </div>
    </Slice>
  )
}

export default VolunteerInfo
