/* eslint-disable camelcase */
import dojoLogoImage from '../components/volunteer/images/coderdojo-logo.svg'
import moment from 'moment'
import React from 'react'

const pluralDays = [
  'Mondays',
  'Tuesdays',
  'Wednesdays',
  'Thursdays',
  'Fridays',
  'Saturdays',
  'Sundays',
]

const singularDays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

const nthStrings = {
  first: 'First',
  '2nd': 'Second',
  '3rd': 'Third',
  '4th': 'Fourth',
  last: 'Last',
}

const formatStartTime = start_time => {
  return start_time
    ? moment(start_time, 'HH:mm')
        .format('h:mma')
        .replace(':00', '')
    : null
}

const formatEndTime = end_time => {
  return end_time
    ? moment(end_time, 'HH:mm')
        .format('h:mma')
        .replace(':00', '')
    : null
}

export const formattedFrequency = ({
  dojo_fields: {
    start_time: startTime,
    end_time: endTime,
    day,
    frequency,
    alternative_frequency,
  },
}) => {
  const formattedStartTime = formatStartTime(startTime)
  const formattedEndTime = formatEndTime(endTime)

  const pluralDay = pluralDays[day - 1]

  const freqString = `${pluralDay} ${formattedStartTime} - ${formattedEndTime}, `
  switch (frequency) {
    case '1/w': {
      return freqString.concat('Weekly')
    }
    case '2/m': {
      return freqString.concat('Every two weeks')
    }
    case '1/m': {
      if (alternative_frequency) {
        const nth = nthStrings[alternative_frequency]
        const singularDay = singularDays[day - 1]
        return `${nth} ${singularDay} of the month, ${formattedStartTime} - ${formattedEndTime}`
      }
      return freqString.concat('Monthly')
    }
    default: {
      return alternative_frequency
    }
  }
}

export const nextEvent = ({ dojo_fields }) => {
  const { upcoming_event, event_date } = dojo_fields
  if (upcoming_event) {
    const eventDate = moment(event_date).format('dddd, MMMM Do')
    const eventString = `Next event on ${eventDate}`

    return <p className="c-volunteer-opportunity__event">{eventString}</p>
  }
  return null
}

export const logoAlt = 'CoderDojo Logo'
export const buttonText = 'Join Dojo'
export const logoImage = dojoLogoImage

const CoderDojoOpportunityHelper = {
  formattedFrequency,
  logoAlt,
  buttonText,
  logoImage,
  nextEvent,
}

export default CoderDojoOpportunityHelper
