import window from '@/helpers/WindowHelper'

export const clear = () => ParamsHelper.setAll()

export const get = (key, defaultValue = null) =>
  ParamsHelper.getAll().get(key) || defaultValue

export const getAll = () => new URLSearchParams(window.location.search)

export const set = (key, value, defaultValue) => {
  if (value === defaultValue) {
    ParamsHelper.unset(key)
  } else {
    let params = ParamsHelper.getAll()
    params.set(key, value)
    ParamsHelper.setAll(params)
  }
}

export const setAll = (params) => {
  if (params instanceof URLSearchParams) {
    const paramsString = params.toString()

    // If there are no params, then need to replaceState with current pathname to remove any existing query string
    params = paramsString ? `?${paramsString}` : window.location.pathname
  }

  window.history.replaceState({}, document.title, params)
}

export const unset = (key) => {
  let params = ParamsHelper.getAll()
  params.delete(key)

  ParamsHelper.setAll(params)
}

const ParamsHelper = {
  clear,
  get,
  getAll,
  set,
  setAll,
  unset,
}

export default ParamsHelper
