import './volunteer-search-result.scss'

import React, { useContext, useRef } from 'react'
import { Slice, TypeBase } from 'raspberry-pi-bits'
import LoadingAnimation from '@/components/shared/loading-animation'
import VolunteerContext from '@/contexts/volunteer'
import VolunteerOpportunity from '@/components/volunteer/volunteer-opportunity'
import VolunteerSearchNoResults from '@/components/volunteer/no-results'
import CodeClubOpportunityHelper from '@/helpers/code-club-opportunity'
import CoderDojoOpportunityHelper from '@/helpers/coderdojo-opportunity'
import Paginator from '@/components/shared/paginator'

export const opportunityWithHelper = opp => {
  const { type } = opp
  const helper =
    type === 'dojo' ? CoderDojoOpportunityHelper : CodeClubOpportunityHelper
  return <VolunteerOpportunity key={opp.id} opp={opp} helper={helper} />
}

const VolunteerSearchResults = () => {
  const {
    searchPerformed,
    opportunities,
    totalResults,
    page,
    nextPage,
    prevPage,
    totalPages,
    loading,
    error,
    geocodedLocation,
  } = useContext(VolunteerContext)

  const scrollRef = useRef(null)

  if (!searchPerformed) {
    return null
  }

  if (loading) {
    return (
      <Slice>
        <div className="o-container">
          <div className="c-volunteer-search-result__loader">
            <LoadingAnimation />
          </div>
        </div>
      </Slice>
    )
  }

  if (error) {
    return (
      <Slice>
        <div className="o-container">
          <p>
            Oops! We&apos;re sorry but we had a technical issue while finding
            you clubs. Please try again in a few minutes.
          </p>
        </div>
      </Slice>
    )
  }

  return (
    <Slice>
      <div className="o-container">
        <div ref={scrollRef} className="c-volunteer-search-result">
          <TypeBase element="p">
            {totalResults} Clubs around&nbsp;
            <strong>{geocodedLocation}</strong>
          </TypeBase>
          <div className="c-panel">
            {opportunities.map(opp => {
              return opportunityWithHelper(opp)
            })}
            <VolunteerSearchNoResults />
            <Paginator
              next={nextPage}
              prev={prevPage}
              totalPages={totalPages}
              currentPage={page}
              scrollRef={scrollRef}
            />
          </div>
        </div>
      </div>
    </Slice>
  )
}

export default VolunteerSearchResults
