/* eslint-disable no-console */
export const getOpportunities = async (location, page) => {
  const url = `${process.env.GATSBY_CLUBS_SERVICE_URL}/volunteer_opportunities?location=${location}&page=${page}`
  let data = []
  let response

  try {
    response = await fetch(url, {})
  } catch (error) {
    console.log('REQUEST_FAILED:', error.toString())
    return { error: error.toString() }
  }

  try {
    data = await response.json()
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.log(`[${response.status}] Request failed:`, error)
    }
  }

  return data
}

const VolunteerHelper = {
  getOpportunities,
}

export default VolunteerHelper
