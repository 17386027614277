import ApplicationLayout from '@/Layouts/Application'
import React from 'react'
import TranslationCTA from '@/components/translations/cta'
import { VolunteerContextProvider } from '@/contexts/volunteer'
import VolunteerHero from '@/components/volunteer/hero'
import VolunteerInfo from '@/components/volunteer/volunteer-info'
import VolunteerSearchForm from '@/components/volunteer/volunteer-search-form'
import VolunteerSearchResults from '@/components/volunteer/volunteer-search-results'

const VolunteerPage = () => {
  return (
    <ApplicationLayout preferBits={true}>
      <VolunteerContextProvider>
        <VolunteerHero />
        <VolunteerSearchForm />
        <VolunteerSearchResults />
        <VolunteerInfo />
        <TranslationCTA />
      </VolunteerContextProvider>
    </ApplicationLayout>
  )
}

export default VolunteerPage
