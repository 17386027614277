import './volunteer-no-results.scss'

import React, { useContext } from 'react'
import VolunteerContext from '@/contexts/volunteer'
import { TypeBase } from 'raspberry-pi-bits'

const VolunteerSearchNoResults = () => {
  const { totalResults, geocodedLocation } = useContext(VolunteerContext)

  if (totalResults !== 0) {
    return null
  }

  return (
    <div className="c-volunteer-no-results">
      <TypeBase element="p">
        We couldn&apos;t find any clubs near {geocodedLocation}, please check
        that we've searched the correct location.
      </TypeBase>
      <TypeBase element="p">
        If there&apos;s no club near you yet, you can create your own!
      </TypeBase>
      <TypeBase element="p">
        Follow one of the links below to the community that suits you best.
      </TypeBase>
    </div>
  )
}

export default VolunteerSearchNoResults
