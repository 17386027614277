import React from 'react'
import PropTypes from 'prop-types'

export const paginateClickHandler = (event, callBack, scrollRef) => {
  let offset = null
  if (scrollRef) {
    offset = scrollRef.current.offsetTop - 20
  }
  callBack(event, offset)
}

const Paginator = ({ currentPage, next, prev, scrollRef, totalPages }) => {
  const morePages = Number(currentPage) < totalPages
  const prevPages = Number(currentPage) > 1

  if (totalPages == 0) {
    return null
  }

  return (
    <div className="c-pagination">
      <button
        className={
          prevPages ? 'c-pagination__link c-pagination__link--prev' : 'u-hidden'
        }
        onClick={event => paginateClickHandler(event, prev, scrollRef)}
      >
        <span className="u-hidden">Previous page</span>
      </button>
      <span className="c-pagination__label">
        Page {currentPage} of {totalPages}
      </span>
      <button
        className={
          morePages ? 'c-pagination__link c-pagination__link--next' : 'u-hidden'
        }
        onClick={event => paginateClickHandler(event, next, scrollRef)}
      >
        <span className="u-hidden">Next page</span>
      </button>
    </div>
  )
}

Paginator.propTypes = {
  next: PropTypes.func.isRequired,
  prev: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.string.isRequired,
  scrollRef: PropTypes.object,
}

export default Paginator
