import './loading-animation.scss'

import React from 'react'

const LoadingAnimation = () => (
  <div className="c-loading-animation">
    <div className="c-loading-animation__monitor">
      <div className="c-loading-animation__screen">&nbsp;</div>
    </div>
  </div>
)

export default LoadingAnimation
